.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  align-items: center;
  padding-bottom: 100px;
}

.App-link {
  color: #61dafb;
}

/* Add a black background color to the top navigation */
.topnav {
  width: 100%;
  //background-color: #333;
  overflow: visible;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: center;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 50px;
  text-decoration: none;
  font-size: 30px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4CAF50;
  color: white;
}

h1 {
 font-family: 'Lobster', Georgia, Times, serif;
 font-size: 70px;
 line-height: 100px;
}

p {
 font-family: 'Cabin', Helvetica, Arial, sans-serif;
 font-size: 25px;
 line-height: 25px;
}

/* Style all font awesome icons */
.fab {
  padding: 20px;
  font-size: 100px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

/* github */
.fa-github {
  color: grey;
}

ul {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}


.tabul li {
  padding-top: 20px;
  padding-bottom: 20px
}

.tabul li a {
  color: grey;
}

.Site-Tab-List button {
  color: white;
  background-color: rgba(0,0,0,.25);
}

// get rid of firefox ugly dotted lines around buttons
:focus {outline:none;}
::-moz-focus-inner {border: 0;}

.rwt__tab[aria-selected="true"] {
  background-color: rgba(0,0,0,.35);
}
